import { SortingState, flexRender } from "@tanstack/react-table"
import dayjs from "dayjs"
import { useRouter } from "next/router"
import { useEffect, useMemo } from "react"
import { useTranslation } from "react-i18next"

import Spinner from "@/components/elements/LoadingSpinner"
import CollaboratorPill from "@/components/elements/Pill/CollaboratorPill"
import Pill from "@/components/elements/Pill/Pill"
import { EHeaderIds } from "@/pages/certificates"
import { useCurrentUserContext } from "context/CurrentUserContext"
import {
  ECertificateStatuses,
  ECertificateTypes,
  TCertificateForTable,
  TCertificateRequestForTable,
  useCertificateTypes,
} from "hooks/data"
import useTable from "hooks/useTable"
import { cn } from "utils"

import CertificateRequestRowMenu from "./CertificateRequestRowMenu"
import CertificateRowMenu from "./CertificateRowMenu"
import CertificateRowStatusPill from "./CertificateRowStatusPill"
import useCertificatesRequestsTableRows from "./useCertificatesRequestsTableRows"
import useCertificatesTableRows from "./useCertificatesTableRows"
import Table from "../../../components/tables/Table"

type Props = {
  data?: TCertificateForTable[]
  sortBy?: string
  sortDirection?: "asc" | "desc"
  setSortBy: (sortBy?: SortingState) => void
  isLoading?: boolean
  isError?: boolean
  isIdle?: boolean
  extraData?: TCertificateRequestForTable[]
  onRowClick?: (row: MixedRow) => void
}

export type CertificateRow = {
  isCertificateRequest?: never;
  StatusID: number
  EmployeeName: string
  CertificateID: number
  TypeID: ECertificateTypes
  ModDate: string
  Collaborators: Collaborator[]
  deleteDisabled?: boolean
  duplicateDisabled?: boolean
  editDisabled?: boolean
  StatusCode?: ECertificateStatuses
  StatusName?: string
  Termin?: string
}
export type CertificateRequestRow = TCertificateRequestForTable & {
  isCertificateRequest: true
  Collaborators: Collaborator[]
}

export type MixedRow = CertificateRow | CertificateRequestRow

export type Collaborator = {
  isHR: boolean
  name: string
  isAssigned?: boolean
}

const getFormattedDate = (date: string) => {
  if (!date) return ""
  return dayjs(date).format("DD.MM.YYYY HH:mm")
}

const CertificatesTable = ({
  sortBy,
  sortDirection = "desc",
  setSortBy,
  isLoading,
  data,
  extraData,
  onRowClick,
}: Props) => {
  const router = useRouter()

  const { t: tHeaders } = useTranslation("certificatesPage", {
    keyPrefix: "tableHeaders",
  })

  const { isUser, UILanguage } = useCurrentUserContext()
  const { data: certificateTypes } = useCertificateTypes()

  const { rows } = useCertificatesTableRows(data)
  const requestsRows = useCertificatesRequestsTableRows(extraData)
  const sumRowsColumns: MixedRow[] = useMemo(() => [...requestsRows ?? [], ...rows ?? []], [
    rows,
    requestsRows
  ])

  const {
    table,
    setSortingState,
    isLoadedState,
    sortingState,
  } = useTable({
    data: sumRowsColumns ?? [],
    columns: (columnHelper) => [
      columnHelper.accessor("StatusID", {
        header: tHeaders("status") ?? "",
        cell: (props) => props.row.original.isCertificateRequest
          ? (
            <CertificateRowStatusPill
              statusCode={ECertificateStatuses.REQUEST}
            />
          )
          : (
            <CertificateRowStatusPill
              statusCode={props.row.original.StatusCode ?? ECertificateStatuses.IN_PROGRESS}
              statusName={props.row.original.StatusName}
              termin={props.row.original.Termin}
            />
          ),
      }),
      columnHelper.accessor("EmployeeName", {
        header: tHeaders("employeeName") ?? "",
        cell: (props) => (
          <span
            className={cn(
              "font-semibold",
              props.row.original.isCertificateRequest && "italic"
            )}
          >
            {props.row.original.isCertificateRequest
              ? props.row.original.FullName
              : props.row.original.EmployeeName}
          </span>
        ),
      }),
      columnHelper.accessor("CertificateID", {
        header: tHeaders("certificateId") ?? "",
        cell: (props) => (
          <Pill type={"labelGray"}>
            {props.row.original.isCertificateRequest
              ? <span className="whitespace-nowrap">
                REQ-{props.row.original.CertificateRequestID}
              </span>
              : `#${props.row.original.CertificateID}`
            }
          </Pill>
        ),
      }),
      columnHelper.accessor("TypeID", {
        header: tHeaders("certificateType") ?? "",
        cell: (props) => {
          const certTypeId = props.row.original.isCertificateRequest ? props.row.original.CertificateTypeID : props.row.original.TypeID
          const type = certificateTypes?.find(
            (item) => item.CatZeugnisTypID === certTypeId
          )
          return (
            <span className="flex w-max">
              {type?.BezeichnungML[UILanguage] ?? ""}
            </span>
          )
        },
      }),
      columnHelper.accessor("Collaborators", {
        header: tHeaders("collaborators") ?? "",
        cell: (props) => {
          const collaborators = props.row.original.Collaborators
          return (
            <div className="flex gap-2">
              {collaborators.map((item, index) => {
                return (
                  <CollaboratorPill
                    key={index}
                    role={item.isHR ? "HR" : "LM"}
                    name={item.name}
                    assigned={item.isAssigned}
                    // showAcronym
                  />
                )
              })}
            </div>
          )
        },
      }),
      columnHelper.accessor("ModDate", {
        header: tHeaders("lastModified") ?? "",
        cell: (props) => getFormattedDate(props.row.original.isCertificateRequest ? props.row.original.CreaDate ?? new Date().toISOString() : props.row.original.ModDate),
      }),
      columnHelper.accessor(() => `menu`, {
        id: "menu",
        header: "",
        cell: (props) => {
          if (props.row.original.isCertificateRequest) {
            return (
              <CertificateRequestRowMenu
                data={props.row.original}
                handleOpen={onRowClick}
              />
            )
          }
          return <CertificateRowMenu data={props.row.original} />
        },
      }),
    ],
  })

  useEffect(() => {
    setSortingState([
      {
        id: sortBy ?? EHeaderIds.LAST_MODIFIED,
        desc: sortDirection === "desc",
      },
    ])
  }, [sortDirection, sortBy, setSortingState])

  const handleToggle = (id: string) => {
    const isTargetActive = sortingState[0]?.id === id
    if (!isTargetActive) {
      setSortBy([
        {
          id,
          desc: true,
        },
      ])
    } else {
      if (sortingState[0]?.desc) {
        setSortBy([
          {
            id,
            desc: false,
          },
        ])
      } else {
        setSortBy(undefined)
      }
    }
  }

  const handleRowClick = (id: number) => {
    const path = `/certificates/${id}/edit#${isUser ? "tasks" : "basic"}`
    void router.push(path)
  }

  return (
    <div className="mb-6">
      {/* {(isEmptyState || isIdle || isError) && (
        <div className="flex items-center font-semibold">No data</div>
      )} */}
      {isLoading && (
        <div>
          <Spinner />
        </div>
      )}

      {isLoadedState && (
        <Table>
          <thead>
            {table.getHeaderGroups().map((headerGroup) => (
              <Table.HeaderRow key={headerGroup.id}>
                {headerGroup.headers.map((header) => (
                  <Table.HeaderCell
                    key={header.id}
                    fixed={header.id === "menu"}
                    disabled={header.id === "Collaborators"}
                    order={header.column.getIsSorted()}
                    onClick={() => handleToggle(header.id)}
                  >
                    {flexRender(
                      header.column.columnDef.header,
                      header.getContext()
                    )}
                  </Table.HeaderCell>
                ))}
              </Table.HeaderRow>
            ))}
          </thead>
          <tbody>
            {table.getRowModel().rows.map((row) => {
              return <Table.Row
                key={row.id}
                disabled={row.original.isCertificateRequest ? false : row.original.editDisabled}
                onClick={() => {
                  onRowClick?.(row.original)
                  if (!row.original.isCertificateRequest) {
                    handleRowClick(row.original.CertificateID)
                  }
                }}
              >
                {row.getVisibleCells().map((cell) => (
                  <Table.Cell key={cell.id} fixed={cell.column.id === "menu"}
                  >
                    {flexRender(cell.column.columnDef.cell, cell.getContext())}
                  </Table.Cell>
                ))}
              </Table.Row>
            })}
          </tbody>
        </Table>
      )}
    </div>
  )
}

export default CertificatesTable
