import type { ReactNode } from "react"

import { useCurrentUser } from "hooks/data"
import { ELanguages } from "src/constants"

type TProps = {
  title: string | ReactNode
  total?: string | number
  children: ReactNode
}

const CatLanguageToHTMLLanguage = {
  [ELanguages.DE]: "de",
  [ELanguages.EN]: "en",
  [ELanguages.IT]: "it",
  [ELanguages.FR]: "fr",
}

export default function TablePage(props: TProps): JSX.Element {
  const { children, title, total } = props
  const { data } = useCurrentUser()

  const userLanguage = data?.CatSprache.ID ?? ELanguages.DE
  const htmlLanguage = CatLanguageToHTMLLanguage[userLanguage]

  return (
    <div className="p-14" lang={htmlLanguage}>
      <h1 className="mb-8 text-lg font-semibold capitalize">
        {title}{" "}
        {total != null && (
          <span className="labelGray ml-2 text-base">{total}</span>
        )}
      </h1>
      {children}
    </div>
  )
}
