/* eslint-disable import/order */
import { useMemo } from "react"
import { ECertificateTypes, TCertificateRequestForTable } from "hooks/data"
import { Collaborator } from "./CertificatesTable"

const useCertificatesRequestsTableRows = (
  data?: TCertificateRequestForTable[]
) => {
  const rows = useMemo(() => {
    return data?.map((item) => {
      const collaborators: Collaborator[] = [
        {
          isHR: false,
          name: item.LineManager || "",
          isAssigned: false,
        },
      ]
      return {
        isCertificateRequest: true as const,
        FullName: item.FullName,
        CertificateRequestID: item.CertificateRequestID,
        CertificateTypeID: item.CertificateTypeID as ECertificateTypes,
        Collaborators: collaborators,
        DateOfBirth: item.DateOfBirth,
        EmployeeNumber: item.EmployeeNumber,
        LanguageID: item.LanguageID,
        Comment: item.Comment,
        CreaDate: item.CreaDate,
      }
    }) ?? []
  }, [data])

  return rows
}

export default useCertificatesRequestsTableRows
