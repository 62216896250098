/* eslint-disable import/order */
import { useRouter } from "next/router"
import { useTranslation } from "react-i18next"
import { useQueryClient } from "react-query"

import { useCurrentUserContext } from "context/CurrentUserContext"
import {
  useCreateCertificate,
  certificateKeys,
  CreateCertificatePayload,
  useLocations,
  ECertificateTypes,
} from "hooks/data"
import z from 'zod';
import useGenericForm from "hooks/useGenericForm"

export const AllowedCertificateLanguages = z.enum(["de", "en", "fr", "it"]);


const useCreateNewCertificateForm = () => {
  const router = useRouter()
  const queryClient = useQueryClient()
  const locationsQuery = useLocations()

  const { t: tForm } = useTranslation("certificatesPage", {
    keyPrefix: "createCertificateModal",
  })
  const { t: tFormShared } = useTranslation("certificateEditor", {
    keyPrefix: "actions.modal.shared",
  })

  const { UILanguage, currentUser } = useCurrentUserContext()

  const { mutate, status } = useCreateCertificate({
    onSuccess({ ZeugnisID }) {
      void router.push(`/certificates/${ZeugnisID}/edit#basic`)
      void queryClient.invalidateQueries(certificateKeys.allForTableInfinite())
    },
  })

  const { submit, values, ...form } = useGenericForm({
    zodSchema: () =>
      z.object({
        employeeId: z.number({
          required_error: tForm("errorEmployeeRequired") ?? "",
        }),
        certificateType: z.nativeEnum(ECertificateTypes, {
          required_error: tForm("errorCertificateTypeRequired") ?? "",
        }),
        lineManagerId: z.number().optional(),
        localHRUserId: z.number().optional(),
        certificateLanguage: AllowedCertificateLanguages.optional(),
      }),

    onSubmit: (data) => {
      const defaultLocation =
        locationsQuery.data?.find(({ IsDefault }) => IsDefault) ||
        locationsQuery.data?.[0]

      if (!defaultLocation) {
        form.setError("root", {
          message: "No default location found",
        })
        return
      }

      if (
        data.lineManagerId &&
        data.localHRUserId &&
        data.lineManagerId === data.localHRUserId
      ) {
        form.setError("localHRUserId", {
          message: tFormShared("errorCollaborator") ?? "",
        })
        return
      }

      if (data.lineManagerId === currentUser?.BenutzerID) {
        form.setError("lineManagerId", {
          message: tFormShared("errorCollaborator") ?? "",
        })
        return
      }
      if (data.localHRUserId === currentUser?.BenutzerID) {
        form.setError("localHRUserId", {
          message: tFormShared("errorCollaborator") ?? "",
        })
        return
      }

      const payload: CreateCertificatePayload = {
        BeurteilterMitarbeiter: {
          ID: data.employeeId,
        },
        CatSprache: {
          ID: data.certificateLanguage ?? UILanguage,
        },
        CatZeugnisTyp: {
          ID: data.certificateType,
        },
        CatStandort: {
          ID: defaultLocation.CatStandortID,
        },
      }

      if (data.lineManagerId) {
        payload.LineManagerBenutzer = {
          ID: data.lineManagerId,
        }
      }

      if (data.localHRUserId) {
        payload.LocalHRBenutzer = {
          ID: data.localHRUserId,
        }
      }

      mutate(payload)
    },
  })

  return {
    submit,
    values,
    form,
    status,
  }
}

export default useCreateNewCertificateForm
