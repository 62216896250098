import { useMemo } from "react"

import { useCurrentUserContext } from "context/CurrentUserContext"
import { TCertificateForTable } from "hooks/data"

import { CertificateRow, Collaborator } from "./CertificatesTable"

const useCertificatesTableRows = (data?: TCertificateForTable[]) => {
  const { currentUser, isUser, isHr, isSuperHr } = useCurrentUserContext()

  const rows = useMemo<CertificateRow[] | undefined>(() => {
    return data?.map((item) => {
      const collaborators: Collaborator[] = [
        {
          isHR: true,
          name: item.ResponsibleHRName,
          isAssigned: item.ResponsibleHRID === item.EditorID,
        },
      ]
      if (item.LocalHRID && item.LocalHRName) {
        collaborators.push({
          isHR: true,
          name: item.LocalHRName,
          isAssigned: item.LocalHRID === item.EditorID,
        })
      }
      if (item.LineManagerID && item.LineManagerName) {
        collaborators.push({
          isHR: false,
          name: item.LineManagerName,
          isAssigned: item.LineManagerID === item.EditorID,
        })
      }

      const isInProgressOrBlocked =
        item.StatusCode === "in_progress" || item.StatusCode === "blocked"
      const deleteDisabled =
        (isInProgressOrBlocked &&
          !isSuperHr &&
          !(isHr && currentUser?.BenutzerID === item.ResponsibleHRID)) ||
        isUser
      const editDisabled =
        (isHr || isUser) && item.EditorID !== currentUser?.BenutzerID

      return {
        StatusID: item.StatusID,
        StatusCode: item.StatusCode,
        EmployeeName: item.EmployeeName,
        CertificateID: item.CertificateID,
        TypeID: item.TypeID,
        ModDate: item.ModDate,
        Termin: item.Termin,
        StatusName: item.StatusName,
        Collaborators: collaborators,
        deleteDisabled,
        duplicateDisabled: isInProgressOrBlocked || isUser,
        editDisabled,
      }
    })
  }, [currentUser?.BenutzerID, data, isHr, isSuperHr, isUser])

  return {
    rows,
  }
}

export default useCertificatesTableRows
