import { Combobox } from "@headlessui/react"
import classNames from "classnames"
import { TFunction } from "i18next"
import * as _ from "lodash-es"
import { XIcon } from "lucide-react"
import { ChangeEvent, Key, useEffect, useState, useRef } from "react"
import { useTranslation } from "react-i18next"

import {
  Tooltip,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger,
} from "@/components/ui/Tooltip"
import {
  Checkmark as CheckmarkIcon,
  ChevronUpDown as ChevronUpDownIcon,
} from "assets/icons"
import { isEmpty } from "utils/array"

import FloatPlacement from "./FloatPlacement"
import Pill from "../elements/Pill/Pill"

export type TOption<TValue> = {
  value: TValue
  primary: string
  label?: string | null
  className?: string
}

type TProps<TValue> = {
  onSearchTermChange: (searchTerm: string) => void
  options?: TOption<TValue>[]
  defaultOption?: TOption<TValue>
  onChange?: (option?: TOption<TValue>) => void
  placeholder?: string | null
  disabled?: boolean
  minSearchTermLength?: number
  className?: string
  label?: TFunction | string | null
  showReset?: boolean
}

export default function CustomCombobox<TValue>(
  props: TProps<TValue>
): JSX.Element {
  const {
    onSearchTermChange,
    options = [],
    placeholder,
    onChange,
    disabled,
    defaultOption,
    minSearchTermLength = 0,
    className,
    label,
    showReset,

  } = props

  const { t } = useTranslation("selectComponent")

  const placeholderValue = {
    value: -1 as unknown as TValue,
    primary: "",
  }

  const inputTextCopyRef = useRef<HTMLSpanElement>(null)
  const inputRef = useRef<HTMLInputElement>(null)
  const [inputTextWidth, setInputTextWidth] = useState<number>(0)
  const [pillWidth, setPillWidth] = useState<number>(0)
  const [chosenOption, setChosenOption] =
    useState<TOption<TValue>>(placeholderValue)

  useEffect(() => {
    if (defaultOption && (chosenOption.value as unknown as number) === -1) {
      setChosenOption(defaultOption)
    }
    if (defaultOption === undefined) {
      setChosenOption({ value: -1 as unknown as TValue, primary: "" })
    }

    setInputTextWidth(inputTextCopyRef.current?.getBoundingClientRect().width ?? 0)
  }, [defaultOption, chosenOption.value])

  useEffect(() => {
    const inputWidth = inputRef.current?.getBoundingClientRect().width ?? 0
    setPillWidth(inputWidth - inputTextWidth)
  }, [inputTextWidth])

  const nothingFound = isEmpty(options)

  return (
    <Combobox
      as="div"
      className={classNames("Combobox relative", className)}
      value={chosenOption}
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      onChange={(option: any) => {
        // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
        setChosenOption(option)
        // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
        if (onChange) onChange(option)
      }}
      {...{ disabled }}
    >
      {label && (
        <span
          className={classNames(["mb-2 block text-sm font-bold text-black"])}
        >
          {label as string}
        </span>
      )}

      <FloatPlacement>
        <div className="relative">
          <Combobox.Input
            ref={inputRef}
            placeholder={placeholder ?? t("placeholder") ?? ""}
            className={classNames([
              "h-10 w-full rounded-md border-none text-base",
              "py-2 pl-3 pr-14 shadow-sm",
              "ring-1 ring-inset ring-black",
              "focus:outline-none focus:ring-2 focus:ring-inset focus:ring-ocean ",
              disabled &&
              "pointer-events-none select-none cursor-not-allowed bg-gray-200 opacity-50",
            ])}
            onChange={_.debounce(
              ({ target }: ChangeEvent<HTMLInputElement>) => {
                if (target.value.length > minSearchTermLength) {
                  onSearchTermChange(target.value)
                }
              },
              300
            )}
            displayValue={(option: TOption<TValue>) => option.primary}
            autoComplete="off"
          />

          {chosenOption.label && (
            <TooltipProvider>
              <Tooltip>
                <div className="flex justify-end pl-3 absolute left-0 top-0 h-full w-full"
                  style={
                    {
                      maxWidth: `calc(${inputRef.current?.getBoundingClientRect().width ?? 0}px - 50px)`
                    }
                  }>
                  <span
                    ref={inputTextCopyRef}
                    className="flex-shrink-0 opacity-0 pointer-events-none"
                  >
                    {chosenOption.primary}
                  </span>
                  <TooltipTrigger
                    style={{
                      width: `calc(${Math.max(pillWidth, 70)}px - 0.75rem)`,
                    }}
                    className="cursor-default pl-2 h-full"
                    type="button">
                    <Pill
                      type="labelGray"
                    >
                      <span className="truncate">
                        {chosenOption.label}
                      </span>
                    </Pill>
                  </TooltipTrigger>
                </div>
                {chosenOption.label &&
                  <TooltipContent side="top">
                    {chosenOption.label}
                  </TooltipContent>
                }
              </Tooltip>
            </TooltipProvider>
          )}

          {defaultOption && showReset && (
            <button
              className="absolute inset-y-0 right-8 flex items-center hover:opacity-50"
              onClick={(e) => {
                e.stopPropagation()
                onChange && onChange(undefined)
                onSearchTermChange && onSearchTermChange("")
              }}
            >
              <XIcon className="h-5 w-5 text-gray-400" aria-hidden="true" />
            </button>
          )}
          <Combobox.Button className="absolute inset-y-0 right-0 flex items-center rounded-r-md pr-3 pl-2 focus:outline-none">
            <ChevronUpDownIcon className="icon" aria-hidden="true" />
          </Combobox.Button>
        </div>

        <Combobox.Options
          className={classNames([
            "z-10 mt-1 max-h-60",
            "w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg border border-black focus:outline-none",
          ])}
        >
          {nothingFound && (
            <div className="px-4 pb-2 pt-3">{t("nothingFound")}</div>
          )}
          {options.map((option) => (
            <Combobox.Option
              key={option.value as unknown as Key}
              value={option}
              className={({ active }) =>
                classNames([
                  "relative overflow-hidden cursor-default select-none py-2 pl-3 pr-9",
                  active && "bg-clearSky",
                  option.className,
                ])
              }
            >
              {({ selected }) => (
                <>
                  <div className="flex items-center">
                    <span className={`ml-1 ${selected ? "font-semibold" : ""}`}>
                      {option.primary}
                    </span>
                    {option.label && (
                      <Pill className="ml-2" type="labelGray">
                        {option.label}
                      </Pill>
                    )}
                  </div>
                  {selected && (
                    <span
                      className={classNames([
                        "text-black",
                        "absolute inset-y-0 right-0 flex items-center pr-4",
                      ])}
                    >
                      <CheckmarkIcon className="h-5 w-5" aria-hidden="true" />
                    </span>
                  )}
                </>
              )}
            </Combobox.Option>
          ))}
        </Combobox.Options>
      </FloatPlacement>
    </Combobox>
  )
}
