import { useRouter } from "next/router"
import { useQueryClient } from "react-query"

import { useCurrentUserContext } from "context/CurrentUserContext"
import { useDownloadContext } from "context/DownloadContext"
import {
  ECertificateStatuses,
  ECertificateTypes,
  certificateKeys,
  useCertificateTypes,
  useDeleteCertificate,
  useDuplicateCertificate,
} from "hooks/data"
import { useEmailReminderCertificate } from "hooks/data/certificate/useEmailReminderCertificate"
import { ELanguages } from "src/constants"

import { CertificateRow } from "./CertificatesTable"

type Props = {
  data: CertificateRow
}

const useCertificateRowAction = ({ data }: Props) => {
  const router = useRouter()
  const client = useQueryClient()

  const { isUser, isSuperHr, UILanguage } = useCurrentUserContext()
  const certificateTypesQuery = useCertificateTypes()

  const { downloadCertificate } = useDownloadContext()
  const downloadCertificateAs = (type: "PDF" | "DOCX") => {
    const certificateName = certificateTypesQuery.data?.find(
      ({ CatZeugnisTypID }) => CatZeugnisTypID === data.TypeID
    )
    downloadCertificate.mutate({
      certificateId: data.CertificateID,
      type,
      completed: data.StatusCode === ECertificateStatuses.DONE,
      // WORKAROUND:
      // Server returns rubbish instead of legit name for employee confirmation -
      // but only when UI langauge is set to DE.
      // RESOLVED BY:
      // https://github.com/skriba-ch/skriba-ink/issues/350
      ...(data.TypeID === ECertificateTypes.CONFIRMATION &&
        certificateName &&
        UILanguage === ELanguages.DE && {
          customName: `${certificateName.BezeichnungML[UILanguage]} ${data.EmployeeName}`,
        }),
    })
  }

  const duplicateMutation = useDuplicateCertificate({
    onSuccess(response) {
      if (isSuperHr) {
        void router.push(`/certificates/${response.ZeugnisID}/edit#basic`)
      } else {
        void client.invalidateQueries(certificateKeys.allForTableInfinite())
      }
    },
  })

  const deleteMutation = useDeleteCertificate({
    onSuccess() {
      void client.invalidateQueries(certificateKeys.allForTableInfinite())
    },
  })

  const sendEmailMutation = useEmailReminderCertificate()

  const handleEdit = () => {
    void router.push(
      `/certificates/${data.CertificateID}/edit#${isUser ? "tasks" : "basic"}`
    )
  }

  return {
    downloadCertificateAs,
    duplicateCertificate: () => duplicateMutation.mutate(data.CertificateID),
    deleteCertificate: (id: number) => deleteMutation.mutate(id),
    sendEmailReminder: (id: number) => sendEmailMutation.mutate(id),
    editCertificate: handleEdit,
  }
}

export default useCertificateRowAction
