import classNames from "classnames"
import { FC, useEffect, useState } from "react"

type Props = Omit<JSX.IntrinsicElements["input"], "onChange"> & {
  className?: string
  label?: string
  initiallyChecked?: boolean
  onChange?: (state: boolean) => void
}

const Checkbox: FC<Props> = ({
  id,
  label,
  initiallyChecked,
  onChange,
  className,
  disabled,
  ...props
}) => {
  const [isChecked, setIsChecked] = useState(false)

  useEffect(() => {
    if (initiallyChecked) {
      setIsChecked(true)
    } else {
      setIsChecked(false)
    }
  }, [initiallyChecked])

  return (
    <div className="flex items-center">
      <input
        id={id}
        className={classNames([
          "h-4 w-4",
          "",
          "rounded border border-black",
          !disabled &&
            "bg-gray-100 checked:bg-electricBlue hover:cursor-pointer focus:ring-blue-500",
          disabled &&
            "border-opacity-10 bg-gray-200 checked:bg-blue-300 cursor-not-allowed hover:bg-gray-200 checked:hover:bg-blue-300",
          className,
        ])}
        type="checkbox"
        disabled={disabled}
        checked={isChecked}
        onChange={(event) => {
          const { checked } = event.target
          setIsChecked(checked)
          if (onChange) {
            onChange(checked)
          }
        }}
        {...props}
      />
      {label && (
        <label
          className={classNames(
            "ml-2 text-sm font-medium text-gray-900",
            disabled && "cursor-not-allowed opacity-50"
          )}
          htmlFor={id}
        >
          {label}
        </label>
      )}
    </div>
  )
}

export default Checkbox
