import { FC, useMemo } from "react"
import { useTranslation } from "react-i18next"
import { useQueryClient } from "react-query"

import Menu from "@/components/ui/Menu"
import {
  Certificates as CertificateIcon,
  Garbage as GarbageIcon,
  HorizontalDots,
} from "assets/icons"
import { certificateKeys, useDeleteCertificateRequest } from "hooks/data"
import { useConfirm } from "hooks/useConfirm"

import { CertificateRequestRow, MixedRow } from "./CertificatesTable"

type Props = {
  data: CertificateRequestRow
  handleOpen?: (row: MixedRow) => void
}

const CertificateRequestRowMenu: FC<Props> = ({ data, handleOpen }) => {
  const client = useQueryClient()

  const { t: tActions } = useTranslation("certificatesPage", {
    keyPrefix: "actions",
  })
  const { t: tModal } = useTranslation("modalComponent")
  const { t: tCertificates } = useTranslation("certificatesPage", {
    keyPrefix: "deleteCertificateRequestModal",
  })

  const { mutate: deleteCertificateRequest } = useDeleteCertificateRequest({
    onSuccess() {
      void client.invalidateQueries(
        certificateKeys.allRequestsForTableInfinite()
      )
    },
  })

  const certId = data.CertificateRequestID

  const getConfirmation = useConfirm({
    title: tCertificates("title", { titleData: certId }),
    description: tCertificates("description"),
    confirmText: tCertificates("confirm"),
    cancelText: tModal("cancel"),
  })

  const options = useMemo(() => {
    return [
      {
        type: "default" as const,
        label: tActions("open"),
        onClick: () => handleOpen?.(data),
        icon: (
          <span className="icon" aria-hidden="true">
            <CertificateIcon />
          </span>
        ),
      },
      {
        type: "separator" as const,
      },
      {
        type: "destructive" as const,
        label: tActions("delete"),
        onClick: () => {
          getConfirmation()
            .then(() => deleteCertificateRequest(certId))
            .catch(() => {})
        },
        icon: (
          <span className="icon" aria-hidden="true">
            <GarbageIcon />
          </span>
        ),
      },
    ]
  }, [
    data,
    certId,
    tActions,
    handleOpen,
    getConfirmation,
    deleteCertificateRequest,
  ])

  return (
    <>
      <Menu options={options}>
        <button className="-mx-4 rounded-full p-2 hover:bg-gray-200">
          <HorizontalDots className="h-5 w-5 fill-white " />
        </button>
      </Menu>
    </>
  )
}

export default CertificateRequestRowMenu
