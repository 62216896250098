import { Bubbles } from "assets/icons"

interface Props {
  title: string
  body: string
  footer?: string
}

export default function EmptyState(props: Props): JSX.Element {
  const { title, body, footer } = props

  return (
    <div className="relative">
      <div className="flex justify-center">
        <Bubbles />
      </div>
      <div className="container absolute top-[30%] left-[50%] w-[360px] -translate-x-2/4 rounded-3xl bg-[#f2f5f6] p-8 text-center">
        <div className="text-lg font-semibold">{title}</div>
        <div className="text-m mt-3">{body}</div>
        {footer && <div className="text-m mt-3">{footer}</div>}
      </div>
    </div>
  )
}
