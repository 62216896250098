import { useEffect, useMemo, useState } from "react"

import { useUser, useUsers } from "hooks/data"
import { EUserRoles } from "src/constants"
import { IUser } from "types"

import Combobox from "../forms/Combobox"

type Props = {
  onChange: (value?: number, user?: IUser) => void
  value?: number
  searchName?: string
  searchRole?: keyof typeof EUserRoles
  getInitialUser?: (value: IUser) => void
  label?: string
  hasReset?: boolean
  disabled?: boolean
}

const ComboboxUser = ({
  onChange,
  value,
  searchName: initialSearchTerm,
  searchRole,
  getInitialUser,
  label,
  hasReset,
  disabled,
}: Props) => {
  const [searchTerm, setSearchTerm] = useState(initialSearchTerm)

  const role = searchRole ? EUserRoles[searchRole] : undefined

  const { data, isSuccess: dataIsSuccess } = useUsers(
    {
      $top: 50,
      searchrole: role,
      $orderby: "Vorname asc",
      searchterm: searchTerm,
    },
    {
      staleTime: 0,
    }
  )

  const [fetchedUsers, setFetchedUsers] = useState<
    {
      primary: string
      value: IUser["BenutzerID"]
    }[]
  >([])

  useEffect(() => {
    if (!data) {
      return
    }

    const fetchedUsersOnPage = data.map((user) => ({
      primary: `${user.Vorname} ${user.Nachname}`,
      value: user.BenutzerID,
    }))

    setFetchedUsers(fetchedUsersOnPage || [])
  }, [data])

  // Check if the selected user is in the list of available users.
  // If no, only then fetch him and add to the list.
  const userInList = useMemo(
    () => fetchedUsers?.some((option) => option.value === value),
    [fetchedUsers, value]
  )

  const { data: userData, isSuccess: userDataIsSuccess } = useUser(
    !userInList ? value : undefined
  )

  useEffect(() => {
    if (!value || userInList || !userData) {
      return
    }

    const selectedUserData = {
      primary: `${userData.Vorname} ${userData.Nachname}`,
      value: userData.BenutzerID,
    }


    setFetchedUsers((prev) => {
      const exists = prev.some((option) => option.value === value)

      if (exists) {
        return [
          selectedUserData,
          ...prev.filter((option) => option.value !== value),
        ]
      } else {
        return [selectedUserData, ...prev]
      }
    });
  }, [value, userInList, userData])

  const handleChange = (optionValue?: number) => {
    const selectedUser = data?.find(
      ({ BenutzerID }) => BenutzerID === optionValue
    )
    if (selectedUser) {
      onChange(optionValue, selectedUser)
    } else {
      setSearchTerm("")
      onChange(undefined)
    }
  }

  const initialValue = fetchedUsers?.find((option) => option.value === value)

  useEffect(() => {
    if (!value || !getInitialUser) {
      return
    }

    if (userInList) {
      if (!dataIsSuccess) {
        return
      }
      const selectedUser = data.find(({ BenutzerID }) => BenutzerID === value)

      if (!selectedUser) {
        return
      }

      getInitialUser(selectedUser)
    } else {
      if (!userDataIsSuccess) {
        return
      }

      getInitialUser(userData)
    }
  }, [
    dataIsSuccess,
    userDataIsSuccess,
    fetchedUsers,
    value,
    getInitialUser,
    userInList,
    data,
    userData,
  ])

  return (
    <Combobox
      disabled={disabled}
      showReset={hasReset}
      label={label}
      onSearchTermChange={setSearchTerm}
      options={fetchedUsers}
      onChange={(option) => {
        handleChange(option?.value)
      }}
      defaultOption={initialValue}
    />
  )
}

export default ComboboxUser
