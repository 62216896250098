import dayjs from "dayjs"
import { FC } from "react"
import { useTranslation } from "react-i18next"

import { ECertificateStatuses } from "hooks/data"
import { cn } from "utils"

function getLabelClassName(code?: ECertificateStatuses) {
  switch (code) {
    case "done":
      return "labelGreen"

    case "in_progress":
      return "labelYellow"

    case "request":
      return "labelBlack"

    default:
      return "labelGray"
  }
}

const pillStyles = cn("flex w-max text-xs font-semibold")

type Props = {
  statusCode: ECertificateStatuses
  statusName?: string
  termin?: string
}

const CertificateRowStatusPill: FC<Props> = ({
  statusCode,
  statusName,
  termin,
}) => {
  const { t } = useTranslation("certificatesPage")

  if (statusCode === ECertificateStatuses.REQUEST) {
    const text = t("certificateStatuses.request")

    return (
      <span
        className={cn([
          getLabelClassName(statusCode),
          pillStyles,
        ])}
      >
        {text}
      </span>
    )
  }

  const isOverdue = dayjs(termin).add(1, "day").isBefore(dayjs())
  const text = t("certificateStatuses.overdue")

  if (isOverdue && statusCode !== ECertificateStatuses.DONE) {
    return (
      <span
        className={cn(
          "labelRed mr-auto flex shrink-0 place-items-center text-xs font-semibold"
        )}
      >
        {text}
      </span>
    )
  }
  return (
    <span
      className={cn([
        getLabelClassName(statusCode),
        pillStyles,
      ])}
    >
      {statusName}
    </span>
  )
}

export default CertificateRowStatusPill
