import * as _ from "lodash-es"
import { useState, useEffect } from "react"

interface TScrollPosition {
  x: number
  y: number
}

const getPosition = (): TScrollPosition => ({
  x: window.scrollX,
  y: window.scrollY,
})

interface IOptions {
  throttle?: number
}

export default function useWindowScrollPosition(
  options: IOptions = {
    throttle: 150,
  }
): TScrollPosition {
  const [position, setPosition] = useState(
    typeof window !== "undefined" ? getPosition() : { x: 0, y: 0 }
  )

  useEffect(() => {
    const handleScroll = _.throttle(() => {
      setPosition(getPosition())
    }, options.throttle)

    window.addEventListener("scroll", handleScroll, { passive: true })

    return () => {
      handleScroll.cancel()
      window.removeEventListener("scroll", handleScroll)
    }
  }, [options.throttle])

  return position
}
