import { ReactNode, useState } from "react"
import { useTranslation } from "react-i18next"

import { Garbage as GarbageIcon } from "assets/icons"

import Button from "./Button"
import Modal from "./Modal"

type TModalProps = {
  show: boolean
  onClose: () => void
  onConfirm: () => void
}

export function DeleteConfirmationModal(props: TModalProps): JSX.Element {
  const { show, onClose, onConfirm } = props

  const { t } = useTranslation("modalComponent", {
    keyPrefix: "delete",
  })

  return (
    <Modal
      {...{ show, onClose }}
      title={`${t("title")}`}
      confirmButtonText={`${t("confirm")}`}
      onConfirmButtonClick={() => {
        onClose()
        onConfirm()
      }}
    >
      {t("body")}
    </Modal>
  )
}

type TProps = {
  children: ReactNode
  onConfirm: () => void
  disabled: boolean
}

export default function DeleteButton(props: TProps): JSX.Element {
  const { disabled, onConfirm, children = "Delete" } = props

  const [showModal, setShowModal] = useState(false)

  return (
    <>
      <Button
        secondary
        {...{ disabled }}
        onClick={() => setShowModal(true)}
        Icon={GarbageIcon}
      >
        {children}
      </Button>
      <DeleteConfirmationModal
        show={showModal}
        onClose={() => setShowModal(false)}
        {...{ onConfirm }}
      />
    </>
  )
}
