import { FC } from "react"
import { useTranslation } from "react-i18next"

import { cn } from "utils"

const getAcronym = (name: string) => {
  const words = name.split(" ")
  const first = words[0]
  const last = words[words.length - 1]
  return `${first[0]}${last[0]}`
}

type Props = {
  name?: string
  role: "HR" | "LM"
  assigned?: boolean
  showAcronym?: boolean
}

const CollaboratorPill: FC<Props> = ({ name, role, assigned, showAcronym }) => {
  const { t } = useTranslation("roles")
  if (!name) return null

  const getName = () => {
    if (!showAcronym) {
      return name
    }
    return assigned ? name : getAcronym(name)
  }

  return (
    <div className="flex h-6 w-max gap-1">
      <div
        className={cn(
          "flex h-6 w-6 shrink-0 place-content-center place-items-center rounded-full text-xs font-black text-white",
          assigned ? "bg-electricBlue " : "bg-black "
        )}
      >
        {t(role)}
      </div>
      <span
        className={cn(
          "flex h-6 w-full items-center truncate break-all rounded-full border-[1.5px] px-2 py-1 text-xs",
          assigned
            ? "border-electricBlue text-electricBlue"
            : "border-black text-black"
        )}
      >
        {getName()}
      </span>
    </div>
  )
}

export default CollaboratorPill
