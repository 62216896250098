import classNames from "classnames"

import { ArrowRight as ArrowRightIcon } from "assets/icons"

export enum ESortDirections {
  ASCENDING = "asc",
  DESCENDING = "desc",
}

export type THeader = {
  id?: string
  label: string
  className?: string
  sortDirection?: ESortDirections
  onClick?: () => void
}

type TRowBaseProps = {
  cells: Record<string, number | string | boolean | null>
  rowIndex: number
}
export type TDataRow<TRowProps> = TRowProps & { id: number | string }

type TSimpleTableProps<TRowProps> = {
  RowComponent: (props: TRowProps & TRowBaseProps) => JSX.Element
  dataRows?: Array<
    TDataRow<
      TRowProps & { cells: Record<string, number | string | boolean | null> }
    >
  >
  dataHeaders?: THeader[]
  sortBy?: string
  classNameTable?: string
}

export default function SimpleTable<TRowProps>({
  dataRows = [],
  dataHeaders = [],
  RowComponent,
  sortBy,
  classNameTable,
}: TSimpleTableProps<TRowProps>): JSX.Element {
  return (
    <div className="overflow-hidden rounded-md border border-black">
      <table
        className={classNames([
          "SimpleTable",
          "w-full",
          "table-fixed",
          "divide-y divide-black",
          classNameTable,
        ])}
      >
        <thead className="bg-yellow-200">
          <tr>
            {dataHeaders?.map(
              ({ id, label, className = "", sortDirection, onClick }) => (
                <th
                  key={label}
                  scope="col"
                  className={classNames([
                    "px-6 py-3",
                    "text-left text-sm uppercase tracking-wider text-black",
                    className,
                  ])}
                  {...{ onClick }}
                >
                  <div className="flex items-center">
                    <span
                      className={classNames([sortDirection ? "mr-1" : null])}
                    >
                      {label}
                    </span>
                    {sortBy === id && sortDirection && (
                      <ArrowRightIcon
                        className={classNames([
                          "icon",
                          sortDirection === ESortDirections.DESCENDING
                            ? "rotate-90"
                            : "-rotate-90",
                        ])}
                      />
                    )}
                  </div>
                </th>
              )
            )}
          </tr>
        </thead>

        <tbody className="divide-y divide-black bg-white">
          {dataRows.map((rowProps, idx) => {
            const { id } = rowProps
            return <RowComponent {...rowProps} key={id} rowIndex={idx} />
          })}
        </tbody>
      </table>
    </div>
  )
}
