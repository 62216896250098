/* eslint-disable import/order */
import { FC, useEffect, useState } from "react"
import { useTranslation } from "react-i18next"

import ComboboxUser from "@/components/data/ComboboxUser"
import Button from "@/components/elements/Button"
import SelectGeneric from "@/components/forms/SelectGeneric"
import {
  Dialog,
  DialogContent,
  DialogFooter,
  DialogHeader,
} from "@/components/ui/dialog"
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormMessage,
  FormServerMessage,
} from "@/components/ui/form"
import { Label, labelClassnames } from "@/components/ui/label"
import { useCurrentUserContext } from "context/CurrentUserContext"
import {
  ECertificateTypes,
  TEmployeeForTable,
  certificateKeys,
  useCertificateLanguages,
  useCertificateTypes,
  useDeleteCertificateRequest,
  useEmployee,
  useEmployeesForTable,
  useUsers,
} from "hooks/data"
import { cn } from "utils"
import { useQueryClient } from "react-query"

import useCreateNewCertificateForm, { AllowedCertificateLanguages } from "./useCreateNewCertificateForm"
import { CertificateRequestRow } from "../CertificatesTable"
import { calculateMatchScore } from "./calculateMatchScore"
import { IUser } from "types"
import dayjs from "dayjs"
import ComboboxEmployee from "@/components/data/ComboboxEmployee"

type Props = {
  open: boolean
  setOpen: (open: boolean) => void
  setCertificateSearchTerm: (input: string) => void
  requestedCertificate?: CertificateRequestRow
}

const CreateNewCertificateModal: FC<Props> = ({
  open,
  setOpen,
  setCertificateSearchTerm,
  requestedCertificate,
}) => {
  const { submit, values, form, status } = useCreateNewCertificateForm()
  const { setValue, watch } = form
  const { employeeId } = watch()

  const { UILanguage } = useCurrentUserContext()
  const { t: tForm } = useTranslation("certificatesPage", {
    keyPrefix: "createCertificateModal",
  })

  const [searchTerm, setSearchTerm] = useState("")
  const certificateLanguages = useCertificateLanguages()
  const certificateTypesQuery = useCertificateTypes()
  const certificateTypeOptions =
    certificateTypesQuery.data?.map((type) => ({
      value: type.CatZeugnisTypID,
      primary: type.BezeichnungML[UILanguage],
    })) || []
  const client = useQueryClient()

  const { data: employee } = useEmployee(employeeId)

  const { data: users } = useUsers();


  const { data: employeesForRequestMatch } = useEmployeesForTable({}, {
    staleTime: Infinity
  })

  const { mutate: deleteCertificateRequest } = useDeleteCertificateRequest({
    onSuccess() {
      void client.invalidateQueries(
        certificateKeys.allRequestsForTableInfinite()
      )
    },
  })

  const [matchedEmployeeForRequest, setMatchedEmployeeForRequest] = useState<{
    employeeId: TEmployeeForTable['ID'],
    score: number
  } | null>(null)

  const [matchedBenutzerForRequest, setMatchedBenutzerForRequest] = useState<{
    benutzerId: IUser['BenutzerID'],
    score: number
  } | null>(null)

  useEffect(() => {
    const getDefaultCertificateType = () => {
      const employeeHasJob =
        employee?.Beschaeftigungen && employee?.Beschaeftigungen.length > 0

      if (
        employeeHasJob &&
        employee?.Beschaeftigungen[employee?.Beschaeftigungen.length - 1]
          .CatAnstellungsart?.ID === 3
      ) {
        return employee?.Austrittsdatum
          ? ECertificateTypes.INTERNSHIP
          : ECertificateTypes.INTERMEDIARY_INTERNSHIP
      } else {
        return employee?.Austrittsdatum
          ? ECertificateTypes.WORK
          : ECertificateTypes.INTERMEDIARY_WORK
      }
    }

    if (employee && !values.certificateType) {
      setValue(
        "certificateType",
        getDefaultCertificateType()
      )
    }
  }, [employee, values.certificateType, setValue])

  const certificateTypeSelected = certificateTypeOptions.find(
    (item) => item.value === requestedCertificate?.CertificateTypeID
  )
  const certificateLanguageSelected = certificateLanguages.data?.find(
    (lang) => lang.CatSpracheID === requestedCertificate?.LanguageID
  )

  useEffect(() => {
    if (!requestedCertificate) {
      return
    }

    if (requestedCertificate.CertificateTypeID) {
      setValue('certificateType', requestedCertificate.CertificateTypeID)
    }

    const employeesToMatch = employeesForRequestMatch ? employeesForRequestMatch.map((employeeToMatch) => ({
      id: String(employeeToMatch.ID),
      employeeNumber: employeeToMatch.PersonalNumber,
      fullName: employeeToMatch.EmployeeName,
      dob: employeeToMatch.DateOfBirth
    })) : []

    const usersToMatch = users ? users.map((userToMatch) => ({
      id: String(userToMatch.BenutzerID),
      fullName: `${userToMatch.Vorname} ${userToMatch.Nachname}`
    })) : []

    const searchEmployee = {
      employeeNumber: requestedCertificate.EmployeeNumber,
      fullName: requestedCertificate.FullName,
      dob: requestedCertificate.DateOfBirth
    }

    const searchLineManager = {
      fullName: requestedCertificate.Collaborators[0].name
    }

    const employeeResult = calculateMatchScore(employeesToMatch, searchEmployee)

    const lineManagerResult = calculateMatchScore(usersToMatch, searchLineManager)

    if (employeeResult && employeeResult.score >= 50) {
      setMatchedEmployeeForRequest({
        employeeId: Number(employeeResult.item.id),
        score: employeeResult.score
      })

      setValue('employeeId', Number(employeeResult.item.id))
    }

    if (lineManagerResult && lineManagerResult.score >= 50) {
      setMatchedBenutzerForRequest({
        benutzerId: Number(lineManagerResult.item.id),
        score: lineManagerResult.score
      })

      setValue('lineManagerId', Number(lineManagerResult.item.id))
    }

    const safeLanguage = AllowedCertificateLanguages.safeParse(requestedCertificate.LanguageID);

    if (safeLanguage.success) {
      setValue('certificateLanguage', safeLanguage.data)
    }
  }, [requestedCertificate, employeesForRequestMatch, users, setValue])

  useEffect(() => {
    if (status !== 'success' || !requestedCertificate?.CertificateRequestID) {
      return;
    }

    deleteCertificateRequest(requestedCertificate.CertificateRequestID)
  }, [status, requestedCertificate?.CertificateRequestID, deleteCertificateRequest])

  // we're redirecting after success
  const submittingNewCertificate = status === "loading" || status === "success"

  return (
    <Dialog open={open} onOpenChange={setOpen}>
      <DialogContent>
        <Form {...form}>
          <form onSubmit={submit}>
            {requestedCertificate ? (
              <DialogHeader
                title={tForm("certificateRequest.title")}
                description={
                  matchedEmployeeForRequest && matchedBenutzerForRequest
                    ? tForm("certificateRequest.descriptionConfirm")
                    : tForm("certificateRequest.descriptionSelect")
                }
              />
            ) : (
              <DialogHeader
                title={tForm("title")}
                description={tForm("description")}
              />
            )}

            {requestedCertificate && (
              <div className="bg-paleYellow rounded p-3 mt-4 flex flex-col gap-1">
                {!matchedEmployeeForRequest && !matchedBenutzerForRequest ? (
                  <p className={labelClassnames}>
                    {tForm("certificateRequest.noDataMatched")}
                  </p>
                ) : !matchedEmployeeForRequest ? (
                  <p className={labelClassnames}>
                    {tForm("certificateRequest.noEmployeeMatch")}
                  </p>
                ) : (
                  !matchedBenutzerForRequest && (
                    <p className={labelClassnames}>
                      {tForm("certificateRequest.noLineManagerMatch")}
                    </p>
                  )
                )}
                <div className="mt-2">
                  <p className={labelClassnames}>
                    {tForm("certificateRequest.dataProvided")}
                  </p>
                  <p>
                    {[
                      requestedCertificate.EmployeeNumber ||
                        tForm("certificateRequest.noEmployeeNumber"),
                      requestedCertificate.FullName,
                      dayjs(requestedCertificate.DateOfBirth).format(
                        "DD-MM-YYYY"
                      ),
                    ].join(", ")}
                  </p>
                  <p>
                    {[
                      certificateTypeSelected?.primary,
                      certificateLanguageSelected?.Bezeichnung,
                      requestedCertificate.Collaborators[0].name ||
                        tForm("certificateRequest.noLineManager"),
                    ].join(", ")}
                  </p>
                </div>
                {requestedCertificate.Comment && (
                  <div className="flex flex-col gap-2 mt-4">
                    <p className={labelClassnames}>
                      {tForm("certificateRequest.comments")}
                    </p>
                    <p>{requestedCertificate.Comment}</p>
                  </div>
                )}
              </div>
            )}

            <div className="grid gap-4 py-8">
              <FormField
                name="employeeId"
                control={form.control}
                render={({ field }) => (
                  <FormItem>
                    <Label>{tForm("labelEmployee")}</Label>
                    <FormControl>
                      <ComboboxEmployee
                        hasReset
                        onChange={(value, user) => {
                          if (user) {
                            setSearchTerm(`${user.Nachname} ${user.Vorname}`)
                          }

                          field.onChange(value)
                        }}
                        value={employeeId}
                      />
                    </FormControl>
                    <FormMessage />

                    <div className="min-h-[20px]">
                      {employee?.EigeneZeugnisse?.length ? (
                        <button
                          className={cn([
                            "ml-auto block text-sm italic",
                            "cursor-pointer underline",
                          ])}
                          onClick={() => {
                            setCertificateSearchTerm(
                              `${employee?.Vorname} ${employee?.Nachname}`
                            )
                            setOpen(false)
                          }}
                        >
                          {tForm("messageShowCertificates")}
                        </button>
                      ) : (
                        <>
                          {searchTerm && (
                            <span
                              className={cn([
                                "block text-right text-sm italic",
                                "text-gray-500",
                              ])}
                            >
                              {tForm("messageNoCertificates")}
                            </span>
                          )}
                        </>
                      )}
                    </div>
                  </FormItem>
                )}
              />
              <FormField
                name="certificateType"
                control={form.control}
                render={({ field }) => (
                  <FormItem>
                    <Label>{tForm("certificateType")}</Label>
                    <FormControl>
                      <SelectGeneric
                        {...field}
                        onChange={(option) =>
                          option.value && field.onChange(option.value)
                        }
                        placeholder={tForm("placeholderCertificateType")}
                        options={certificateTypeOptions}
                        value={certificateTypeOptions.find(
                          (option) => option.value === field.value
                        )}
                        isLoading={certificateTypesQuery.isLoading}
                      />
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                )}
              />

              <DialogHeader description={tForm("optional")} className="mt-4" />
              <FormField
                name="lineManagerId"
                control={form.control}
                render={({ field }) => (
                  <FormItem>
                    <Label>{tForm("lineManager")}</Label>
                    <FormControl>
                      <ComboboxUser {...field} hasReset />
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                )}
              />
              <FormField
                name="localHRUserId"
                control={form.control}
                render={({ field }) => (
                  <FormItem>
                    <Label>{tForm("localHRUser")}</Label>
                    <FormControl>
                      <ComboboxUser {...field} hasReset searchRole="HR" />
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                )}
              />
            </div>

            <FormServerMessage />

            <DialogFooter>
              <Button
                secondary
                onClick={() => {
                  setOpen(false)
                }}
              >
                {tForm("cancel")}
              </Button>
              <Button type="submit" disabled={submittingNewCertificate}>
                {submittingNewCertificate ? tForm("creating") : tForm("create")}
              </Button>
            </DialogFooter>
          </form>
        </Form>
      </DialogContent>
    </Dialog>
  )
}

export default CreateNewCertificateModal
