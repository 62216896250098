import { SortDirection } from "@tanstack/react-table"
import { FC, ReactNode } from "react"

import { ChevronDown2 } from "assets/icons"

import cx from "../../utils/classMerge"

interface BaseProps {
  children?: ReactNode
  className?: string
  onClick?: () => void
  disabled?: boolean
}

type Children = {
  Row: FC<BaseProps>
  Cell: FC<CellProps>
  HeaderRow: FC<BaseProps>
  HeaderCell: FC<SortProps>
}

const Table: FC<BaseProps> & Children = ({ children, className }) => {
  return (
    <div
      className={cx("overflow-auto rounded-md border border-black", className)}
    >
      <table className="w-full divide-y divide-black">{children}</table>
    </div>
  )
}

const Row = ({ children, className, onClick, disabled }: BaseProps) => {
  return (
    <tr
      className={cx(
        "group border-b border-black last-of-type:border-0 hover:bg-gray-100",
        onClick && !disabled && "cursor-pointer active:bg-gray-200",
        className
      )}
      aria-disabled={disabled}
      onClick={() => onClick && !disabled && onClick()}
    >
      {children}
    </tr>
  )
}

type CellProps = BaseProps & {
  fixed?: boolean
}
const Cell = ({ children, className, fixed }: CellProps) => {
  return (
    <td
      className={cx(
        "px-6 py-3 text-sm",
        "group-hover:bg-gray-100",
        fixed && "sticky right-0 z-10 bg-white",
        className
      )}
    >
      {children}
    </td>
  )
}

const HeaderRow = ({ children, className }: BaseProps) => {
  return <tr className={cx("bg-yellow-200", className)}>{children}</tr>
}

interface SortProps extends BaseProps {
  order?: SortDirection | false
  fixed?: boolean
}
const HeaderCell = ({
  children,
  className,
  onClick,
  order,
  disabled,
  fixed,
}: SortProps) => {
  return (
    <th
      className={cx(
        "px-5 py-3 text-left text-sm uppercase tracking-wider text-black",
        fixed && "sticky right-0 z-10 bg-yellow-200",
        className
      )}
    >
      <div
        className={cx(
          "flex w-max items-center",
          onClick &&
            !disabled &&
            !fixed &&
            "cursor-pointer select-none rounded-lg px-1 py-0.5 hover:bg-yellow-300"
        )}
        onClick={() => onClick && !disabled && onClick()}
      >
        {children}
        <span className="flex items-center opacity-70 transition-all">
          {order === "asc" && <ChevronDown2 className="h-4 rotate-180" />}
          {order === "desc" && <ChevronDown2 className="h-4" />}
        </span>
      </div>
    </th>
  )
}

Table.Row = Row
Table.Cell = Cell
Table.HeaderRow = HeaderRow
Table.HeaderCell = HeaderCell

export default Table
