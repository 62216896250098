// eslint-disable-next-line import/no-extraneous-dependencies
import Fuse from 'fuse.js';

interface Employee {
  id: string;
  employeeNumber?: string;
  dob?: string;
  fullName: string;
}

interface SearchDetails {
  dob?: string;
  employeeNumber?: string;
  fullName: string;
}

export function calculateMatchScore(employeeList: Employee[], searchDetails: SearchDetails) {
  const options = {
    includeScore: true,
    includeMatches: true,
    shouldSort: true,
    isCaseSensitive: false,
    threshold: 0.85,
    location: 0,
    distance: 100,
    ignoreLocation: true,
    keys: [
      'fullName'
    ]
  };

  const fuse = new Fuse(employeeList, options);

  const searchPattern = searchDetails.fullName;

  const results = fuse.search(searchPattern);

  const enhancedScores = results.map(result => {
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    let score = result.score!;

    if (searchDetails.dob && result.item.dob === searchDetails.dob) {
      score -= 0.2;
    }

    if (searchDetails.employeeNumber && result.item.employeeNumber === searchDetails.employeeNumber) {
      score -= 0.2;
    }

    score = Math.max(0, Math.min(1, score));

    return {
      ...result,
      enhancedScore: score
    };
  });

  if (enhancedScores.length === 0) {
    return null;
  }

  const bestMatch = enhancedScores.sort((a, b) => a.enhancedScore - b.enhancedScore)[0];
  const finalScore = (1 - bestMatch.enhancedScore) * 100;

  return {
    score: finalScore,
    item: bestMatch.item
  };
}
